/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { useHttp } from '@/middleware/httpMiddleware';

async function getEventTypes (credentialsId, offset = 0, limit = 16) {
  const data = await useHttp(
    '/integrations/calendly/event_types',
    'GET',
    null,
    {
      credentials_id: credentialsId,
      offset,
      limit,
    },
    false
  );

  return data.payload;
}

async function reloadCalendlyIntegration () {
  const data = await useHttp(
    '/integrations/calendly/reload',
    'POST'
  );

  return data.payload;
}

export {
  getEventTypes,
  reloadCalendlyIntegration
};
