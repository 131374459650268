import { computed } from 'vue';
import { StepCardInfo } from '@/components/updateWorkflow/schemas/StepCardInfo';
import i18n from '@/i18n';
import { capitalize } from '@/mixins/capitalizeFilterMixin';
import { useConfig } from '@/mixins/useConfig';
import { useIntegrationsStore } from '@/stores/integrations';

const { $config } = useConfig();

export const getStepInfo = (actionType, action) => {
  const integrationsStore = useIntegrationsStore();
  const integrationsList = computed(() => integrationsStore.integrationsList);
  const checkIntegration = computed(() => integrationsList.value.find(i => i._id === action.integration_id));
  switch (actionType) {
    case 'li_actions':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.performAction.title'),
        'bxs-user-detail',
        { actions: action.actions },
        ''
      );
    case 'li_send_message':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.sendMessage.title'),
        'bx-message-detail',
        { message: action.text_template },
        ''
      );
    case 'li_send_inmail':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.sendInMail.title'),
        'bx-edit',
        { subject: action.subject_template, message: action.text_template },
        ''

      );
    case 'send_email':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.sendEmail.title'),
        'bx-mail-send',
        { email_type: action.email_type, email_types: action.email_types },
        checkIntegration.value ? '' : i18n.t('campaignsPage.editor.steps.sendEmail.errorMessage')
      );
    case 'li_connect':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.sendConnectionRequest.title'),
        'bx-user-plus',
        { message: action.text_template || i18n.t('campaignsPage.editor.steps.sendConnectionRequest.infoMessage') },
        ''
      );
    case 'li_connect_by_email':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.sendConnectionRequestByEmail.title'),
        'bx-user-pin',
        { message: i18n.t('campaignsPage.editor.steps.sendConnectionRequestByEmail.infoMessage') },
        ''
      );
    case 'enrich_profile':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.enrichProfile.title'),
        'bx-diamond',
        { message: i18n.t('campaignsPage.editor.steps.enrichProfile.infoMessage', { name: capitalize($config.value.name) }) },
        ''
      );
    case 'send_webhook':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.sendWebhook.title'),
        'bx-universal-access',
        { message: i18n.t('campaignsPage.editor.steps.sendWebhook.title', { brand: capitalize($config.value.name) }) },
        ''
      );
    case 'li_invite_event':
      return new StepCardInfo(
        'Invite to Event',
        'bx-calendar-event',
        { message: action?.event_link || i18n.t('campaignsPage.editor.steps.inviteEvent.infoMessage') },
        action?.event_link ? '' : i18n.t('campaignsPage.editor.steps.inviteEvent.errorMessage')
      );
    case 'push_to_crm':
      return new StepCardInfo(
        i18n.t('actions.pushToCrm'),
        'bx-line-chart',
        {
          message: `Push prospect data to ${action?.crm_platform === 'default'
            ? (capitalize($config.value.name) + 'CRM')
            : capitalize(action?.crm_platform || '...SELECT PLATFORM')}`,
        },
        ''
      );
    case 'manual_task':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.manualTask.title'),
        'bx-calendar-check',
        { subject: action.manual_task_settings.title, message: action.manual_task_settings.note, emptyState: 'No description' },
        ''
      );
    case 'manual_call':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.manualCall.title'),
        'bx-phone',
        { message: action.manual_task_settings.note, emptyState: 'No call note' },
        ''
      );
    case 'send_sms':
      return new StepCardInfo(
        i18n.t('campaignsPage.editor.steps.sendSms.title'),
        'bx-message-square-dots',
        { message: action.text_template, emptyState: 'No message' },
        ''
      );
    default:
      return {};
  }
};
