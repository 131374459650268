<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <portal to="body-end-2">
    <modal
      :key="name"
      :name="name"
      :width="width"
      height="auto"
      classes="component"
      :adaptive="true"
      @closed="handleClose"
    >
      <div class="modal">
        <div>
          <h3 class="text_xl">
            {{ title }}
          </h3>
          <p class="tw-text-s tw-mt-6">
            {{ text }}
          </p>
          <p
            v-if="warningText"
            class="mt-2 booster-warning-modal__warning"
          >
            <i
              class="bx bxs-error text_xs"
            />
            <span class="text_xs">
              {{ warningText }}
            </span>
          </p>
          <default-button
            class="modal__close-button"
            color="neutral"
            form="circle"
            size="m"
            icon-name="bx-x"
            @action="handleClose"
          />
        </div>
        <div class="tw tw-flex tw-justify-end tw-w-full tw-space-x-2 tw-mt-6">
          <default-button
            class=""
            color="neutral"
            :title="cancelText"
            form="rectangle"
            size="m"
            @action="discardChanges"
          />
          <default-button
            class=""
            color="primary"
            :title="primaryText"
            form="rectangle"
            size="m"
            @action="saveChanges"
          />
        </div>
      </div>
    </modal>
  </portal>
</template>

<script setup>
  import { watch } from 'vue';
  import { useModal } from '@/use/useModal';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';

  // name: 'ConfirmChangesModal',

  const props = defineProps({
    show: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: function () {
        return this.$t('modals.confirmChanges.default.title');
      },
    },
    text: {
      type: String,
      default: function () {
        return this.$t('modals.confirmChanges.default.text');
      },
    },
    cancelText: {
      type: String,
      default: function () {
        return this.$t('modals.confirmChanges.default.cancelText');
      },
    },
    warningText: {
      type: String,
      default: '',
    },
    primaryText: {
      type: String,
      default: function () {
        return this.$t('modals.confirmChanges.default.primaryText');
      },
    },
    name: {
      type: String,
      default: 'confirmChangesModal',
    },
    width: {
      type: String,
      default: '550px',
    },
    stay: {
      type: Boolean,
      default: false,
    },
    next: {
      type: Function,
      default: () => {
        this.$router.push('/');
      },
    },
  });
  const $modal = useModal();
  const emit = defineEmits(['update:show', 'save-changes', 'cancel']);
  watch(() => props.show, () => props.show ? $modal.show(props.name) : $modal.hide(props.name));
  function discardChanges () {
    emit('update:show', false);
    emit('cancel');
    props.next();
  }
  function saveChanges () {
    emit('save-changes');
    emit('update:show', false);
    if (!props.stay) {
      props.next();
    }
  }
  const handleClose = () => {
    emit('update:show', false);
  };
</script>

<style lang="scss" scoped>
  .modal {
    background-color: #F1F3F4;
    height: 100%;
    border-radius: 15px;
    color: black;
    font-size: 1.275rem;

    &__small-text {
      font-size: 0.9975rem;
      color: #5F5F5F;
      font-weight: normal;
    }

    &__buttons {
      display: grid;
      width: 100%;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 12px;
      white-space: nowrap;
    }
    &__warning {
      padding: 12px 16px;
      display: flex;
      align-items: flex-start;
      gap: 8px;
      color: #f29900;
      background: #fef6e9;
      border-radius: 12px;
    }
 }

  .primary-btn {
    width: 100%;
    height: 40px;
    font-size: 1.0575rem;
  }
</style>
