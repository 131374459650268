<template>
  <div class="flex-column flex-gap-16 pb-25 pt-25 pl-2 pr-2">
    <h1 class="text_s text_weight_accent tw-flex tw-justify-between">
      {{ t('inboxPage.chatSection.infoSidebar.placeholdersInfo.title') }}
      <default-button
        v-if="hasCustomFields()"
        :title="t('inboxPage.chatSection.infoSidebar.placeholdersInfo.buttons.newPlaceholder')"
        icon-name="bx-plus"
        color="none"
        title-color="link"
        :has-paddings="false"
        form="rectangle"
        size="xs"
        @action="showCustomFieldModal = true"
      />
    </h1>
    <div
      v-if="hasCustomFields()"
      class="flex-column"
    >
      <custom-field-item
        v-for="(value, name, idx) in customFields"
        :key="idx"
        :field-name="name"
        :field-value="value"
        @edit-field="openCustomFieldModal(name, value)"
        @delete-field="openDeleteConfirmationModal(name)"
      />
    </div>
    <div
      v-else
      class="flex-column-centered flex-gap-16"
    >
      <i class="bx bx-code-curly color_tertiary icon_size_24" />
      <span class="color_tertiary text_s">
        {{ t('inboxPage.chatSection.infoSidebar.placeholdersInfo.noExperience') }}
      </span>
      <default-button
        :title="t('inboxPage.chatSection.infoSidebar.placeholdersInfo.buttons.newPlaceholder')"
        icon-name="bx-plus"
        color="neutral"
        form="rectangle"
        size="m"
        @action="showCustomFieldModal = true"
      />
    </div>
    <custom-field-modal
      :field-value="fieldValue"
      :field-name="fieldName"
      :show="showCustomFieldModal"
      :is-edit="isEdit"
      @update:show="handleCustomFieldModal"
      @delete-field="openDeleteConfirmationModal"
      @save-field="editField"
    />
    <delete-confirmation-modal
      :show="showDeleteModal"
      text="Are you sure you want to delete this custom field?"
      :title="`Delete ${fieldNameToDelete}?`"
      button-text="Delete"
      :name="'deleteCustomField'"
      @update:show="showDeleteModal = $event"
      @confirm="deleteCustomField"
    />
  </div>
</template>

  <script setup>
  // PlaceholdersInfo
  import CustomFieldModal from '@/modals/CustomFieldModal.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import CustomFieldItem from '@/components/inboxPage/info/placeholders/CustomFieldItem.vue';
  import DeleteConfirmationModal from '@/modals/DeleteConfirmationModal.vue';
  import { ref } from 'vue';
  import { useSnack } from '@/lib/useSnack';
  import { useI18n } from '@/i18n';
  const props = defineProps({
    profile: {
      type: Object,
      required: true,
    },
  });
  const emit = defineEmits(['update-profile']);
  const { t } = useI18n();
  const fieldName = ref('');
  const fieldNameToDelete = ref('');
  const fieldValue = ref('');
  const isEdit = ref(false);
  const { $snack } = useSnack();
  const showCustomFieldModal = ref(false);
  const showDeleteModal = ref(false);
  const customFields = ref({ ...props.profile.custom_fields });
  // computed do not work
  const hasCustomFields = () => {
    return Object.keys(customFields.value).length;
  };

  function openCustomFieldModal (name = '', value = '') {
    isEdit.value = true;
    fieldValue.value = value;
    fieldName.value = name;
    showCustomFieldModal.value = true;
  }
  function openDeleteConfirmationModal (name) {
    showCustomFieldModal.value = false;
    fieldNameToDelete.value = name;
    showDeleteModal.value = true;
  }
  function deleteCustomField () {
    delete customFields.value[fieldNameToDelete.value];
    fieldNameToDelete.value = '';
    updateProfile();
    $snack.success(t('inboxPage.chatSection.infoSidebar.placeholdersInfo.deleteNote'));
  }

  function handleCustomFieldModal (show) {
    showCustomFieldModal.value = show;
    if (!show) {
      isEdit.value = false;
      fieldValue.value = '';
      fieldName.value = '';
    }
  }

  function editField ({ name, alias }, oldName) {
    const note = isEdit.value
      ? t('inboxPage.chatSection.infoSidebar.placeholdersInfo.editNote')
      : t('inboxPage.chatSection.infoSidebar.placeholdersInfo.addNote');
    if (oldName) {
      delete customFields.value[oldName];
    }
    customFields.value[name] = alias;
    updateProfile();
    $snack.success(note);
  }

  function updateProfile () {
    handleCustomFieldModal(false);
    emit('update-profile', { custom_fields: customFields.value });
  }

  </script>
