var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['ui-kit-input', { 'ui-kit-input_fulled': _vm.isFulled, 'ui-kit-input_disabled': _vm.isDisabled }],attrs:{"tabindex":0}},[_c('validation-provider',{attrs:{"vid":_vm.vid,"rules":_vm.validationString},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [(_vm.description)?_c('p',{staticClass:"ui-kit-input__description"},[_vm._v(" "+_vm._s(_vm.description)+" ")]):_vm._e(),_c('div',{class:[
        'ui-kit-input__container',
        {
          'ui-kit-input__container_danger': errors.length && !_vm.isNude,
          'ui-kit-input__container_active':
            _vm.active && !errors.length && !_vm.isNude,
          'ui-kit-input__container_nude': _vm.isNude,
        },
      ],style:(_vm.padding + _vm.borderColor)},[(_vm.icon)?_c('i',{class:['bx color_tertiary', 'mr-05', _vm.icon]}):_vm._e(),(_vm.leftImg)?_c('default-avatar',{staticClass:"mr-05",attrs:{"photo-link":_vm.leftImg,"size":"xxxs"}}):_vm._e(),_c('input',{ref:"input",class:['ui-kit-input__item', 'text_' + _vm.size],attrs:{"id":_vm.inputId,"name":_vm.name,"type":_vm.customType,"placeholder":_vm.placeholder,"readonly":_vm.readOnly || _vm.isDisabled,"autocomplete":_vm.autocomplete,"maxlength":_vm.maxCharacters},domProps:{"value":_vm.innerValue},on:{"focus":_vm.onInputFocus,"blur":_vm.onInputBlur,"input":_vm.handleInput,"change":_vm.handleChange,"keypress":_vm.handleInputKeyPress}}),(_vm.type === 'password')?_c('i',{class:[
          'bx margin-left cursor-pointer',
          'bx-' + (_vm.showPassword ? 'hide' : 'show'),
        ],on:{"click":function($event){_vm.showPassword = !_vm.showPassword}}}):_vm._e(),(errors.length && _vm.isNude)?_vm._l((errors),function(error,i){return _c('p',{key:`${error}${i}`,staticClass:"color_danger text_xs tw-mt-1"},[_vm._v(" "+_vm._s(error)+" ")])}):(_vm.hasDropdown)?_c('i',{staticClass:"bx icon_size_16 margin-left bx-chevron-down flex"}):_vm._e(),_vm._t("afterInput")],2),(errors.length && !_vm.isNude)?_vm._l((errors),function(error,i){return _c('div',{key:`${error}${i}`,staticClass:"color_danger text_xs tw-mt-1"},[(error !== 'Fill this field')?_c('p',[_vm._v(" "+_vm._s(error)+" ")]):_vm._e()])}):_vm._e()]}}],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }