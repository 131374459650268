function setLeadConnector (supportId, supportUserId) {
  const chatWidget = document.createElement('chat-widget');
  chatWidget.locationId = supportId;
  chatWidget.promptAvatar = `https://firebasestorage.googleapis.com/v0/b/highlevel-backend.appspot.com/o/locationPhotos%2F${supportId}%2Fchat-widget-person?alt=media&token=${supportUserId}`;
  chatWidget.locale = 'en-us';

  const script = document.createElement('script');
  script.dataResourcesUrl = 'https://widgets.leadconnectorhq.com/chat-widget/loader.js';
  script.src = 'https://widgets.leadconnectorhq.com/loader.js';
  script.type = 'text/javascript';

  const div = document.createElement('div');
  div.dataChatWidget = true;
  div.dataLocationId = supportId;
  div.promptAvatar = `https://firebasestorage.googleapis.com/v0/b/highlevel-backend.appspot.com/o/locationPhotos%2F${supportId}%2Fchat-widget-person?alt=media&token=${supportUserId}`;
  div.locale = 'en-us';

  const head = document.getElementsByTagName('head')[0];
  const body = document.getElementsByTagName('body')[0];
  body.appendChild(chatWidget);
  head.appendChild(script);
  head.appendChild(div);
}
export { setLeadConnector };
