<template>
  <div>
    <transition name="info">
      <div
        v-show="switcher.selectedGroup"
        ref="infoBody"
        class="inbox-page__info-body flex-column"
        @scroll="handlerScroll"
      >
        <component
          :is="component.value + '-info'"
          v-for="component in switcher.list"
          :id="component.value"
          :ref="component.value"
          :key="profile._id + component.value"
          class="inbox-page__info-component"
          :profile="profile"
          @update-profile="$emit('update-profile', $event)"
          @update-profile-prop="$emit('update-profile-prop', $event)"
          @add-phone="$emit('add-phone')"
        />
        <default-button
          class="margin-center mb-4"
          tag="a"
          target="_blank"
          :href="profile.link"
          :title="$t('inboxPage.chatSection.infoSidebar.buttons.openWithLinkedIn')"
          icon-name="bx-chevron-right"
          color="transparent"
          form="rectangle"
          size="m"
          :has-right-icon="true"
        />
      </div>
    </transition>

    <div class="inbox-page__info-navigation">
      <custom-switchers-list
        class="flex-column flex-gap-8"
        button-type="circle"
        :button-style="{
          padding: '8px',
        }"
        :list="switcher.list"
        :selected="switcher.selectedGroup"
        @select="selectGroup"
      />
    </div>
  </div>
</template>
<script>
  import CustomSwitchersList from '@/components/base/CustomSwitchersList.vue';
  import BasicInfo from '@/components/inboxPage/info/basic/BasicInfo.vue';
  import ContactsInfo from '@/components/inboxPage/info/basic/contacts/ContactsInfo.vue';
  import CampaignInfo from '@/components/inboxPage/info/campaign/CampaignInfo.vue';
  import PlaceholdersInfo from '@/components/inboxPage/info/placeholders/PlaceholdersInfo.vue';
  import DealsInfo from '@/components/inboxPage/info/DealsInfo.vue';
  import ExperienceInfo from '@/components/inboxPage/info/experience/ExperienceInfo.vue';
  import MeetingsInfo from '@/components/inboxPage/info/events/MeetingsInfo.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { mapGetters } from 'vuex';
  import { tierFeatures } from '@/data/tiersFeatures';

  export default {
    name: 'InfoInbox',
    components: {
      DefaultButton,
      CustomSwitchersList,
      BasicInfo,
      ContactsInfo,
      CampaignInfo,
      DealsInfo,
      MeetingsInfo,
      ExperienceInfo,
      PlaceholdersInfo,
    },
    props: {
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        tierFeatures,
        canScroll: true,
        switcher: {
          list: [],
          selectedGroup: 'basic',
        },
        deltaY: 0,
      };
    },
    computed: {
      ...mapGetters({
        account: 'account/account',
      }),
    },
    created () {
      this.switcher.selectedGroup = localStorage.getItem('selectedGroup');
      this.initSwitcherList();
    },
    methods: {
      initSwitcherList () {
        this.switcher.list.push(
          { value: 'basic', icon: 'bx-home' },
          { value: 'contacts', icon: 'bx-user' },
          { value: 'campaign', icon: 'bx-briefcase' }
        );

        if (this.tierFeatures.statuses?.calendly?.exist) {
          this.switcher.list.push({ value: 'meetings', icon: 'bx-calendar' });
        }

        if (Object.keys(this.account.crm_tokens).length) {
          this.switcher.list.push({ value: 'deals', icon: 'bx-line-chart' });
        }

        this.switcher.list.push({ value: 'experience', icon: 'bx-buildings' });
        this.switcher.list.push({ value: 'placeholders', icon: 'bx-code-curly' });
      },
      handlerScroll () {
        this.searchSelectedSwitcher();
      },
      searchSelectedSwitcher () {
        if (this.canScroll) {
          this.switcher.list.forEach((e, i) => {
            const elementOffsetTop = this.$refs[e.value][0].$el.offsetTop;
            const elementOffsetHeight = this.$refs[e.value][0].$el.clientHeight;

            const containerHeight = this.$refs.infoBody.offsetHeight + this.$refs.infoBody.scrollTop + 16 * (i + 1);
            const isElementInView = (elementOffsetTop + elementOffsetHeight) <= containerHeight;

            if (this.$refs.infoBody.scrollTop === 0) {
              this.switcher.selectedGroup = 'basic';
            } else if (isElementInView) {
              this.switcher.selectedGroup = e.value;
            }
          });
        }
      },
      async selectGroup (componentName) {
        this.canScroll = false;
        const isSelected = this.switcher.selectedGroup === componentName;
        this.switcher.selectedGroup = isSelected ? '' : componentName;
        localStorage.setItem('selectedGroup', isSelected ? '' : 'basic');
        if (this.switcher.selectedGroup) {
          setTimeout(() => {
            const currentBtn = this.switcher.list.find(btn => btn.value === componentName);
            if (currentBtn) {
              this.$refs.infoBody.querySelector(`#${componentName}`)
                .scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
            }
          }, 300);
        }
        setTimeout(() => {
          this.canScroll = true;
        }, 1000);
      },
    },
  };
</script>
<style lang="scss">
@import "@/components/inboxPage/info/inboxInfo";
</style>
