import { getCurrentInstance } from 'vue';
import { useSnack } from '@/lib/useSnack';

const { $snack } = useSnack();

export const useSocketIo = () => {
  const $socket = getCurrentInstance()?.proxy?.$socket;
  const sockets = getCurrentInstance()?.proxy?.sockets;

  let _timeout = null;

  /**
   * @param {string} event socket event
   * @param {import('vue').Ref<string>|import('vue').Ref<null>} response response from socket
   * @param {() => void|null} callback
   * @param {number} timeout timeout in ms
   */
  const setResponseTimeoutError = (callback = null, timeout = 45000) => {
    _timeout = setTimeout(() => {
      $snack.error('Response timed out, please try again');
      if (callback !== null) {
        callback();
      }
    }, timeout);
  };

  const resetTimeout = () => {
    clearTimeout(_timeout);
  };

  return {
    sockets,
    $socket,
    setResponseTimeoutError,
    resetTimeout,
  };
};
