<template>
  <div
    class="tw-items-center tw-justify-between tw-flex tw-py-2 tw-px-3 hover:tw-bg-surface-50 tw-rounded-lg tw-transition-opacity tw-min-h-10 tw-w-full"
    @mouseover="isHovered = true"
    @mouseleave="isHovered = false"
  >
    <div
      :class="{'tw-max-w-[70%]':isHovered }"
      class="tw-flex tw-flex-col tw-gap-1 tw-max-w-[100%]"
    >
      <span class="tw-text-sm text_overflow-hidden">{{ fieldName }}</span>
      <span class="tw-text-xs text_overflow-hidden tw-text-text-subdued">{{ fieldValue }}</span>
    </div>
    <div class="tw-flex tw-gap-2">
      <default-button
        v-if="isHovered"
        color="none"
        form="square"
        size="s"
        icon-color="color_tertiary"
        icon-name="bx-edit"
        hovered-background-color="background_hovered_neutral_50"
        @action="emit('edit-field')"
      />
      <default-button
        v-if="isHovered"
        color="none"
        form="square"
        size="s"
        icon-color="color_danger"
        hovered-background-color="background_hovered_danger"
        icon-name="bx-trash"
        @action="emit('delete-field')"
      />
    </div>
  </div>
</template>
<script setup>
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  // CustomFieldItem
  import { ref } from 'vue';
  defineProps({
    fieldName: {
      type: String,
      default: '',
    },
    fieldValue: {
      type: String,
      default: '',
    },
  });
  const emit = defineEmits(['delete-field', 'edit-field']);
  const isHovered = ref(false);
</script>
