<template>
  <li
    class="card-emails"
    @click="isShort = !isShort"
  >
    <div class="flex flex-justify-content-between">
      <div class="flex flex-gap-8">
        <div class="flex-column flex-gap-8">
          <default-avatar
            size="xs"
            :photo-link="email.is_me ? selectedCredential.photo_link : profile.photo_link"
            :status-color="email.is_me ? profileStatusColor : ''"
          />
          <i
            v-if="!email.is_me"
            :class="[
              'bx icon_size_16',
              isStarredProfile ? 'bxs-star color_yellow' : 'bx-star color_tertiary',
            ]"
          />
        </div>
        <div>
          <div class="flex-row flex-gap-8 mb-1">
            <p class="text_s text_weight_accent">
              {{ emailSenderText }}
            </p>
            <p class="text_xs color_tertiary">
              {{ '<' + email.sender + '>' }}
            </p>
          </div>
          <div class="flex-row flex-gap-4">
            <p class="text_xs color_tertiary">
              {{ $t('common.to').concat(':') }}
            </p>
            <default-label
              size="xs"
              :label-name="email.receiver"
              custom-type="info"
            />
          </div>
          <div class="flex-row flex-gap-4">
            <p class="text_xs color_tertiary">
              {{ $t('common.subject').concat(':') }}
            </p>
            <p class="text_xs">
              {{ email.subject }}
            </p>
          </div>
        </div>
      </div>
      <div class="flex-column flex-gap-4 flex-align-items-end">
        <p class="text_xs color_tertiary">
          {{ getFromNowUTC(email.created_at, true) || $t('status.pending') }}
        </p>
        <p :class="['text_xs', 'color_' + statusObj.color]">
          {{ statusObj.name }}
        </p>
      </div>
    </div>
    <div
      v-show="!isShort"
      class="card-emails__body"
    >
      <div
        class="card-emails__text"
        @click.stop="() => {}"
      >
        <div
          ref="message"
          class="text_xs"
        />
      </div>
      <default-button
        v-if="!email.is_me"
        class="flex-align-self-end"
        :title="$t('actions.reply')"
        icon-name="bx-reply"
        color="primary"
        form="rectangle"
        size="s"
        @action="$emit('reply', email)"
      />
    </div>
  </li>
</template>
<script>
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar';
  import DefaultLabel from '@/components/base/uiKit/DefaultLabel';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';

  import variables from '@/styles/_variables.module.scss';
  import { mapGetters } from 'vuex';
  import { capitalizeFilterMixin } from '@/mixins/capitalizeFilterMixin';
  import { fromNowFilterMixin } from '@/mixins/fromNowFilterMixin';
  import { markdown } from 'markdown';
  // eslint-disable-next-line prefer-regex-literals
  const urlRegex = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?([-a-zA-Z0-9@:%+~#=]+(\.[-a-zA-Z0-9@:%+~#=])?){2,256}\.[a-z]{2,40}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g
  );
  // eslint-disable-next-line prefer-regex-literals
  const emailRegex = new RegExp(
    /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,4}))/gi
  );
  // eslint-disable-next-line prefer-regex-literals
  const httpRegex = new RegExp(/^http(s)?:\/\//);
  const tags = ['a', 'font', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'bdi', 'bdo', 'body', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'data', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head', 'header', 'hgroup', 'hr', 'html', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'menu', 'meta', 'meter', 'nav', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp', 'section', 'select', 'slot', 'small', 'source', 'span', 'strong', 'style', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'template', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr'];

  export default {
    name: 'CardEmails',
    components: {
      DefaultAvatar,
      DefaultLabel,
      DefaultButton,
    },
    mixins: [
      capitalizeFilterMixin,
      fromNowFilterMixin,
    ],
    props: {
      email: {
        type: Object,
        required: true,
      },
      profile: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        isShort: true,
      };
    },
    computed: {
      ...mapGetters({
        selectedCredential: 'credentials/selectedCredential',
      }),
      emailSenderText () {
        const senderName = this.profile.first_name;
        const from = this.$t('common.from').toLowerCase();
        const provider = this.capitalize(this.email.provider);
        const senderFromProvider = senderName + ': ' + from + ' ' + provider;

        return this.email.is_me
          ? this.$t('common.you')
          : senderFromProvider;
      },
      profileStatusColor () {
        return variables[`status-${this.profile.tag}`] || variables[`status-${this.profile.status}`];
      },
      isStarredProfile () {
        return this.profile.labels.some(label => label.type === 'global' && label.name === 'Starred');
      },
      statusObj () {
        switch (this.email.status) {
        case 'sent':
          return {
            name: this.$t('status.delivered'),
            color: 'success',
          };
        case 'failed':
          return {
            name: this.$t('status.failed'),
            color: 'danger',
          };
        default:
          return {};
        }
      },
      text () {
        const split = this.email.body;
        if (split.startsWith('<')) {
          return this.email.body;
        }
        return this.email.text || '';
      },
    },
    mounted () {
      this.setMessage();
    },
    methods: {
      setMessage () {
        this.important = this.email.is_important;
        const textWithLinks = this.text.replaceAll(/href="(?!https:\/\/|mailto:)/g, 'href="https://');
        let email = new DOMParser().parseFromString(textWithLinks, 'text/html').querySelector('body');
        const isInBrackets = email.innerHTML[0] === '[' && email.innerHTML[email.innerHTML.length - 1] === ']';
        const historyTags = this.findElements(email);
        historyTags.forEach(tag => {
          tag.remove();
        });
        if (isInBrackets) {
          const emailText = markdown.toHTML(email.firstElementChild.innerHTML);
          email = new DOMParser().parseFromString(emailText, 'text/html').querySelector('body');
        }
        const links = email.getElementsByTagName('a');
        [...links].forEach((link) => {
          link.classList.add('message__link');
        });
        // this.$refs.message.innerHTML = email.innerHTML.replace(/(&amp;)?&?nbsp;/img, ' '); оставлю если что-нить сломает
        this.$refs.message.innerHTML = email.innerHTML;
      },
      findElements (parent) {
        const elArray = [];
        const tmp = parent.getElementsByTagName('*');
        for (let i = 0; i < tmp.length; i++) {
          const tagName = tmp[i].tagName.toLowerCase();
          const isTag = !!tags.find((tag) => tagName === tag);
          if (!isTag) {
            elArray.push(tmp[i]);
          }
        }

        return elArray;
      },
    },
  };
</script>
<style lang="scss">
.card-emails {
  padding: 16px;
  border-radius: 8px;
  background: var(--background-color);
  box-shadow: 1px 1px 4px var(--tooltip-shadow);
  transition: 0.5s;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 12px var(--tooltip-shadow);
  }

  &__body {
    @include flex-column;
    gap: 16px;
    margin-top: 16px;
    padding-top: 16px;
    border-top: $border;
  }
  &__text {
    height: auto;
    color: var(--text-color);
    line-height: 18px;
    word-break: break-word;
  }
  &__link {
    text-decoration: underline;
  }
}
</style>
