import { ref } from 'vue';
import { defineStore } from 'pinia';
import { isBoolean } from '@/utils/global/types';
export const useViewStore = defineStore('view', () => {
    const viewSettings = JSON.parse(localStorage.getItem('viewSettings') || '{}');
    const horizontal = ref(viewSettings?.horizontal ?? false);
    const inboxPastMeetings = ref(viewSettings?.inboxPastMeetings ?? true);
    function updateView(update) {
        if (isBoolean(update?.horizontal)) {
            horizontal.value = update.horizontal;
        }
        if (isBoolean(update?.inboxPastMeetings)) {
            inboxPastMeetings.value = Boolean(update.inboxPastMeetings);
        }
        if (!update.skipLocalStorage) {
            const state = {
                horizontal: horizontal.value,
                inboxPastMeetings: inboxPastMeetings.value,
            };
            localStorage.setItem('viewSettings', JSON.stringify(state));
        }
    }
    return {
        horizontal,
        inboxPastMeetings,
        updateView,
    };
});
