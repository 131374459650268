import Vue, { computed, getCurrentInstance } from 'vue';
import VueI18n from 'vue-i18n';
import enMessages from '@/locales/en.json';

Vue.use(VueI18n);

const availableLocales = [
  {
    name: 'English',
    value: 'en',
  },
  {
    name: 'Français',
    value: 'fr',
  },
  {
    name: 'Deutsch',
    value: 'de',
  },
  {
    name: 'Português',
    value: 'pt',
  },
  {
    name: 'Español (España)',
    value: 'es',
  },
];

const currentLocale = getCurrentLocale();

const i18n = new VueI18n({
  locale: setLocale(currentLocale),
  // Default locale
  fallbackLocale: 'en',
  // Preloaded default locale messages
  messages: { en: enMessages },
  // Disable fallback warnings
  silentFallbackWarn: true,
});

// #region i18n helpers
function getCurrentLocale () {
  const preferredLocale = localStorage.getItem('preferredLocale');
  return preferredLocale || 'en';
}

function setLocale (locale) {
  document.querySelector('html').setAttribute('lang', locale);
  return locale;
}

export async function loadLocaleMessages () {
  const messages = await import(
    /* webpackChunkName: "locale-[request]" */ `@/locales/${currentLocale}.json`
  );
  i18n.setLocaleMessage(currentLocale, messages.default);
}
// #endregion

// #region Composable for Composition API
/** @returns {VueI18n} */
export function useI18n () {
  const instance = getCurrentInstance();
  const vm = instance?.proxy || instance || new Vue({});

  const locale = computed({
    get () {
      return i18n.locale;
    },
    set (v) {
      i18n.locale = v;
    },
  });

  const changeLocale = (newLocale) => {
    localStorage.setItem('preferredLocale', newLocale);
    window.location.reload();
  };

  return {
    locale,
    availableLocales,
    t: vm.$t.bind(vm),
    tc: vm.$tc.bind(vm),
    d: vm.$d.bind(vm),
    te: vm.$te.bind(vm),
    n: vm.$n.bind(vm),
    changeLocale,
  };
}
// #endregion

export default i18n;
