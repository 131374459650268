/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { mapGetters } from 'vuex';
export const redirectToSetSpaceMixin = {
  computed: {
    ...mapGetters({
      spacesList: 'account/spacesList',
    }),
  },
  methods: {
    async redirectToSetSpace () {
      if (!['privacy-policy',
        'terms-of-use',
        'set-space',
        'join-space',
        'accept-invite',
        'create-space',
      ]
        .includes(this.$route.name)) {
        if (this.spacesList.length) {
          await this.$router.push('/space/use-space');
        } else {
          await this.$router.push('/space/create-space');
        }
      }
    },
  },

};
