<template>
  <ul
    ref="tasksListRef"
    class="inbox-tasks-list vld-parent"
    :class="{ 'parent-size flex-column-centered': !tasksList.length }"
  >
    <feature-upgrade-placeholder
      v-if="tierFeatures.statuses?.dash_tasks?.upgrade"
      icon="bx-task"
      feature-name="Manual Tasks"
      feature-id="dash_tasks"
      description="Open the possibility of creating and performing manual tasks"
    />

    <template v-else-if="tasksList.length">
      <task-card
        v-for="task in tasksList"
        :key="task._id"
        :ref="el => (tasksRefs[task._id] = el)"
        class="inbox-tasks-list__task"
        :task="task"
        :is-expanded="task._id === expandedTaskId"
        @update:active-messages-group="emit('update:active-messages-group', $event)"
        @update:task="updateTask"
        @close-inbox-modal="emit('close-inbox-modal')"
      />
    </template>

    <p
      v-else
      class="text_s color_tertiary text_center"
    >
      {{ $t('dashboardPage.myTasks.emptyState.prospect') }}
    </p>
  </ul>
</template>

<script setup>
  import { ref, onMounted, onActivated, reactive, nextTick, computed } from 'vue';
  import TaskCard from '@/components/inboxPage/chat/tasks/TaskCard.vue';
  import FeatureUpgradePlaceholder from '@/components/upgradeWalls/FeatureUpgradePlaceholder.vue';
  import { useStore } from '@/store';
  import { useLoader, loadingWrapper } from '@/use/loader';
  import { getProfileTasks } from '@/api/profilesMethods';
  import { tierFeatures } from '@/data/tiersFeatures';

  const props = defineProps({
    profileId: {
      type: String,
      default: '',
    },
    expandedTaskId: {
      type: String,
      default: '',
    },
  });

  const emit = defineEmits(['update:active-messages-group', 'close-inbox-modal']);

  const store = useStore();

  const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);

  // #region Elements refs
  const tasksListRef = ref();
  // #endregion

  // #region Loader
  const isTasksLoading = ref(false);
  const { loaderWatcher } = useLoader({ container: tasksListRef });
  loaderWatcher(isTasksLoading);
  // #endregion

  // #region Tasks list
  const tasksList = ref([]);
  const tasksRefs = reactive({});

  onActivated(async () => {
    let shouldUpdateTasks = false;

    const newTasks = await _getTasks();

    if (newTasks.length !== tasksList.value.length) {
      tasksList.value = newTasks;
      return;
    }

    tasksList.value.forEach((task) => {
      const newTask = newTasks.find((_task) => _task._id === task._id);

      if (new Date(task.updated_at) < new Date(newTask.updated_at)) {
        shouldUpdateTasks = true;
      }
    });

    if (shouldUpdateTasks) {
      tasksList.value = newTasks;
    }
  });

  onMounted(async () => {
    tasksList.value = await getTasks();

    if (props.expandedTaskId) {
      await nextTick();
      tasksRefs?.[props.expandedTaskId]?.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  });

  const _getTasks = async () => {
    return await getProfileTasks(props.profileId, selectedCredential.value._id);
  };

  const getTasks = loadingWrapper(_getTasks, isTasksLoading);
  // #endregion

  // #region Task update
  const updateTask = (newTask) => {
    const targetTask = tasksList.value.find((task) => task._id === newTask._id);
    Object.assign(targetTask, newTask);
  };
  // #endregion
</script>

<style lang="scss">
.inbox-tasks-list {
  padding: 8px 8px 8px 16px;

  &__task {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}
</style>
