<template>
  <div class="flex-row flex-gap-4">
    <default-button
      v-if="tierFeatures.statuses?.ai_assistant_in_inbox?.exist"
      ref="aiPromptToggler"
      data-excluded="aiPromptToggler"
      color="transparent-gr"
      form="ellipse"
      size="m"
      icon-name="bx bxs-magic-wand"
      icon-color="color_tertiary"
      @action="handleOpenGenerate"
    />

    <default-button
      v-if="hasResponsesButton"
      ref="responsesToggler"
      data-excluded="responsesToggler"
      color="transparent-gr"
      form="ellipse"
      size="m"
      icon-name="bx bx-message-alt-detail"
      icon-color="color_tertiary"
      @action="emit('open-responses')"
    />

    <default-button
      ref="emojiesPickerToggler"
      data-excluded="emojiesPickerToggler"
      color="transparent-gr"
      form="ellipse"
      size="m"
      icon-name="bx-smile"
      icon-color="color_tertiary"
      @action="emit('open-emojies')"
    />

    <default-button
      v-if="tierFeatures.statuses?.calendly?.exist"
      ref="eventsToggler"
      data-excluded="eventsToggler"
      color="transparent-gr"
      form="ellipse"
      size="m"
      icon-name="bx-calendar"
      icon-color="color_tertiary"
      @action="handleOpenEvents"
    />
  </div>
</template>

<script setup>
  import { ref } from 'vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { useUpgradeModal } from '@/use/useUpgradeModal';
  import { tierFeatures } from '@/data/tiersFeatures';

  defineProps({
    hasResponsesButton: {
      type: Boolean,
      default: true,
    },
  });

  const emit = defineEmits([
    'open-generate',
    'open-responses',
    'open-emojies',
    'open-events',
  ]);

  const handleOpenGenerate = () => {
    useUpgradeModal(() => {
      emit('open-generate');
    }, 'ai_assistant_in_inbox');
  };

  const handleOpenEvents = () => {
    useUpgradeModal(() => {
      emit('open-events');
    }, 'calendly');
  };

  const aiPromptToggler = ref(null);
  const responsesToggler = ref(null);
  const emojiesPickerToggler = ref(null);
  const eventsToggler = ref(null);

  defineExpose({
    aiPromptToggler,
    responsesToggler,
    emojiesPickerToggler,
    eventsToggler,
  });
</script>
