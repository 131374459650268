<!--
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
-->
<template>
  <div
    :class="['ui-kit-input', { 'ui-kit-input_fulled': isFulled, 'ui-kit-input_disabled': isDisabled }]"
    :tabindex="0"
  >
    <validation-provider
      v-slot="{ errors }"
      :vid="vid"
      :rules="validationString"
    >
      <p
        v-if="description"
        class="ui-kit-input__description"
      >
        {{ description }}
      </p>
      <div
        :class="[
          'ui-kit-input__container',
          {
            'ui-kit-input__container_danger': errors.length && !isNude,
            'ui-kit-input__container_active':
              active && !errors.length && !isNude,
            'ui-kit-input__container_nude': isNude,
          },
        ]"
        :style="padding + borderColor"
      >
        <i
          v-if="icon"
          :class="['bx color_tertiary', 'mr-05', icon]"
        />
        <default-avatar
          v-if="leftImg"
          class="mr-05"
          :photo-link="leftImg"
          size="xxxs"
        />
        <input
          :id="inputId"
          ref="input"
          :value="innerValue"
          :name="name"
          :class="['ui-kit-input__item', 'text_' + size]"
          :type="customType"
          :placeholder="placeholder"
          :readonly="readOnly || isDisabled"
          :autocomplete="autocomplete"
          :maxlength="maxCharacters"
          @focus="onInputFocus"
          @blur="onInputBlur"
          @input="handleInput"
          @change="handleChange"
          @keypress="handleInputKeyPress"
        >
        <i
          v-if="type === 'password'"
          :class="[
            'bx margin-left cursor-pointer',
            'bx-' + (showPassword ? 'hide' : 'show'),
          ]"
          @click="showPassword = !showPassword"
        />
        <template v-if="errors.length && isNude">
          <p
            v-for="(error, i) in errors"
            :key="`${error}${i}`"
            class="color_danger text_xs tw-mt-1"
          >
            {{ error }}
          </p>
        </template>
        <i
          v-else-if="hasDropdown"
          class="bx icon_size_16 margin-left bx-chevron-down flex"
        />
        <slot name="afterInput" />
      </div>
      <template v-if="errors.length && !isNude">
        <div
          v-for="(error, i) in errors"
          :key="`${error}${i}`"
          class="color_danger text_xs tw-mt-1"
        >
          <p
            v-if="error !== 'Fill this field'"
          >
            {{ error }}
          </p>
        </div>
      </template>
    </validation-provider>
  </div>
</template>
<script>
  import { debounce } from 'vue-debounce';
  import { ValidationProvider, extend } from 'vee-validate';
  import * as rules from 'vee-validate/dist/rules';
  import enLocale from 'vee-validate/dist/locale/en.json';
  import DefaultAvatar from '@/components/base/uiKit/DefaultAvatar.vue';
  const { messages } = enLocale;
  export default {
    name: 'DefaultInput',
    components: {
      DefaultAvatar,
      ValidationProvider,
    },
    props: {
      inputId: {
        type: String,
        default: null,
      },
      type: {
        type: String,
        default: 'text',
      },
      size: {
        type: String,
        default: 's', // 'xs', 's'
      },
      value: {
        type: [String, Number],
        default: '',
      },
      shouldTrim: {
        type: Boolean,
        default: false,
      },
      name: {
        // для настройки поля валидации
        type: String,
        default: ' ',
      },
      placeholder: {
        type: String,
        default: '',
      },
      description: {
        type: String,
        default: '',
      },
      icon: {
        type: String,
        default: '',
      },
      leftImg: {
        type: String,
        default: '',
      },
      isNude: {
        type: Boolean,
        default: false,
      },
      isFulled: {
        type: Boolean,
        default: false,
      },
      isDisabled: {
        type: Boolean,
        default: false,
      },
      isFocused: {
        type: Boolean,
        default: false,
      },
      autocomplete: {
        type: String,
        default: '',
      },
      readOnly: {
        type: Boolean,
        default: false,
      },
      hasDropdown: {
        type: Boolean,
        default: false,
      },
      shouldTrimOnChange: {
        type: Boolean,
        default: false,
      },
      vid: {
        type: String,
        default: '',
      },
      debounce: {
        type: Number,
        default: 0,
      },
      brColor: {
        type: String,
        default: '',
      },
      maxCharacters: {
        type: Number,
        default: Infinity,
      },
      validationString: {
        type: String,
        default: '', // https://vee-validate.logaretm.com/v3/guide/rules.html#installing-all-rules
      },
      validationRequired: {
        type: Array,
        default: () => [''], // https://vee-validate.logaretm.com/v3/guide/required-fields.html#creating-a-required-rule
      },
    // Как валидировать форму:
    // https://vee-validate.logaretm.com/v3/advanced/refactoring-forms.html#extracting-input-fields
    },
    data () {
      return {
        active: false,
        innerValue: '',
        showPassword: false,
      };
    },
    computed: {
      borderColor () {
        return this.brColor ? 'border-color:' + this.brColor : '';
      },
      padding () {
        switch (this.size) {
        case 'xs':
          return 'padding: 8px 12px;';
        case 's':
          return 'padding: 10px 12px;';
        default:
          return 'padding: 0;';
        }
      },
      customType () {
        return this.type === 'password'
          ? this.showPassword
            ? 'text'
            : 'password'
          : this.type;
      },
    },
    watch: {
      value (value) {
        if (value !== this.innerValue) {
          this.innerValue = this.value;
        }
      },
      isFocused (value) {
        if (value) {
          setTimeout(() => {
            this.$refs.input.focus();
          }, 100);
        } else {
          this.innerValue = '';
          this.$emit('update:value', '');
          setTimeout(() => {
            this.$refs.input.blur();
          }, 100);
        }
      },
    },
    created () {
      this.addValidation();
      this.emitInput = debounce((e) => {
        this.$emit('update:value', this.innerValue);
        this.$emit('input', this.innerValue, e);
      }, this.debounce);
    },
    mounted () {
      if (this.isFocused) {
        this.$refs.input.focus();
      }

      if (this.value) {
        this.innerValue = this.value;
      }

      this.innerValue = this.type === 'number' && !this.value ? 0 : this.value;
    },
    methods: {
      handleChange (e) {
        if (this.shouldTrimOnChange) {
          this.innerValue = this.innerValue.trim();
          this.emitInput(e);
        }
      },
      onInputFocus () {
        this.active = true;
        this.$emit('focus');
      },
      onInputBlur () {
        this.active = false;
        this.$emit('blur');
      },
      handleInputKeyPress () {
        if (this.innerValue.length >= this.maxCharacters) {
          this.$noty.error(
            this.$t('notifications.messageCanNotBeLongerThan', {
              count: this.maxCharacters,
            })
          );
        }
      },
      handleInput (e) {
        this.innerValue = e.target.value;
        this.emitInput(e);
      },
      addValidation () {
        if (this.validationString) {
          Object.keys(rules).forEach((rule) => {
            extend(rule, {
              ...rules[rule], // copies rule configuration
              message: messages[rule]?.replace('{field}', this.name), // assign message
            });
          });
        }
        if (this.validationString.includes('trimmed')) {
          extend('trimmed', {
            validate (value) {
              return !/^\s+|\s+$/.test(value);
            },
            message: 'This field can not start or end with space',
          });
        }
        if (this.validationString.includes('required')) {
          const testRequired = (value) =>
            !this.validationRequired.includes(value);
          extend('required', {
            validate (value) {
              return {
                required: true,
                valid: testRequired(value),
              };
            },
            computesRequired: true,
            message: 'Fill this field',
          });
        }
      },
    },
  };
</script>
<style lang="scss">
.ui-kit-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
  &_fulled {
    width: 100%;
  }

  &_disabled {
    opacity: 0.7;
    pointer-events: none;
  }

  &__container {
    @include flex-row;
    gap: 4px;
    background: var(--table-bg);
    outline: $border;
    border-radius: 8px;
    width: 100%;
    &_nude {
      border: none;
      outline: none;
      & p {
        white-space: nowrap;
      }
    }

    &_none {
      outline: none;
    }

    &_danger {
      outline: 1px solid $cl-danger;
    }

    &_active {
      outline: 1px solid var(--primary-cl);
    }

    &_disabled {
      outline: 1px solid $cl-neutral-100;
      cursor: not-allowed;
      opacity: 0.48;
    }
  }

  &__description {
    @include text_xs;
    margin-bottom: 4px;
  }

  &__item {
    width: 100%;
    border: none;
    background: var(--background-color);
    color: var(--text-color);
    cursor: inherit;
  }

}
</style>
