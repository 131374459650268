<template>
  <div
    ref="loaderOverlayRef"
    class="parent-size vld-parent"
    :class="{ 'flex-column-centered': isActivityEmpty }"
  >
    <activity-timeline
      :activity="activity"
      :empty-state-text="$t('dashboardPage.activity.emptyState.inboxTimeline')"
      :is-inbox-modal-disabled="true"
    />
  </div>
</template>

<script setup>
  import { ref, onMounted, computed } from 'vue';
  import ActivityTimeline from '@/components/dashboard/activity/ActivityTimeline.vue';
  import { useStore } from '@/store';
  import { useLoader, loadingWrapper } from '@/use/loader';
  import { fetchProfileActivity } from '@/api/profilesMethods';

  const props = defineProps({
    profileId: {
      type: String,
      default: '',
    },
  });

  const store = useStore();

  const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);

  // #region Elements refs
  const loaderOverlayRef = ref();
  // #endregion

  // #region Loader
  const isActivityLoading = ref(false);
  onMounted(() => {
    const feedLoader = useLoader({ container: loaderOverlayRef });
    feedLoader.loaderWatcher(isActivityLoading);
  });
  // #endregion

  // #region Activity
  const activity = ref({});

  const isActivityEmpty = computed(() => !getActivityLength(activity.value));

  onMounted(async () => {
    activity.value = await getActivity();
  });

  const _getActivity = async () => {
    return await fetchProfileActivity(props.profileId, selectedCredential.value._id);
  };

  const getActivity = loadingWrapper(_getActivity, isActivityLoading);

  const getActivityLength = (activity) => {
    return Object.values(activity).reduce((acc, value) => (acc += value.length), 0);
  };
  // #endregion
</script>
