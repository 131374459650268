<template>
  <div
    :class="[
      'responser',
      { 'responser_active': active,
        'responser_gray': $route.name === 'my-prospects'
      },
    ]"
  >
    <generate-prompt-inbox
      v-if="showInputFeature"
      :message="message"
      :profile-id="profile._id"
      :credentials-id="selectedCredential._id"
      request-id-suffix="#messages-responser"
      class="mb-2"
      message-type="linkedin"
      @update:generate="message = $event"
    />

    <div class="responser__content">
      <default-contenteditable
        ref="messageAreaRef"
        v-model="message"
        tag="div"
        :no-n-l="false"
        :no-h-t-m-l="true"
        class="responser__textarea"
        :contenteditable="!isDisabled"
        :data-placeholder="textareaPlaceholder"
        rows="1"
        @focus.native="active = true"
        @blur.native="active = false"
        @keydown.native.enter.exact.prevent
        @keydown.native.enter.exact="sendMessageHandler"
        @keyup.native="handleInput()"
        @click.native="handleInput()"
      />
    </div>
    <div class="mt-1 responser__controls">
      <div class="flex-row flex-gap-8">
        <buttons-responser
          ref="buttonsResponserRef"
          class="flex-align-self-end"
          :is-edit-mode="isEditMode"
          @open-responses="isResponsesActive = !isResponsesActive"
          @open-emojies="isEmojiesActive = !isEmojiesActive"
          @open-generate="showInputFeature = !showInputFeature"
          @open-events="isEventsActive = !isEventsActive"
          @save="emit('update-message', message)"
          @exit-edit-mode="emit('exit-edit-mode')"
        />

        <sender-info
          v-if="showSender"
          :name="selectedCredential.full_name"
          :avatar-url="selectedCredential.photo_link"
        />
      </div>

      <div class="responser__control">
        <default-button
          :title="isEditMode ? t('actions.save') : t('actions.send')"
          color="primary"
          form="rectangle"
          size="m"
          :is-disabled="!message.trim()"
          @action="sendMessageHandler"
        />
        <default-button
          v-show="!isEditMode"
          ref="scheduleTogglerRef"
          icon-name="bx-time"
          color="primary"
          form="square"
          size="m"
          :is-disabled="!message.trim()"
          @action="isScheduleActive = !isScheduleActive"
        />
        <saved-responses
          v-show="isResponsesActive"
          ref="savedResponsesRef"
          class="responser__saved-responses"
          :show-saved="true"
          :profile="profile"
          @insert="insertResponse"
        />
        <Picker
          v-show="isEmojiesActive"
          ref="emojiesPickerRef"
          class="responser__emojies"
          :data="emojiesData"
          :emoji-size="24"
          :native="true"
          set="native"
          :per-line="8"
          :show-preview="false"
          :show-skin-tones="false"
          :i18n="emojiPickerLocales"
          @select="insertEmoji"
        />
        <schedule-message
          v-show="isScheduleActive"
          ref="scheduleRef"
          class="responser__schedule"
          @cancel="isScheduleActive = false"
          @send="sendMessageHandler"
        />
        <event-types
          v-if="isEventsActive"
          ref="eventTypesRef"
          class="responser__events"
          :step="2"
          :link-params="eventTypesLinkParams"
          @insert-link="insertEventLink"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import ButtonsResponser from '@/components/inboxPage/chat/messages/responser/ButtonsResponser';
  import SavedResponses from '@/components/inboxPage/chat/messages/responser/savedResponses/SavedResponses';
  import ScheduleMessage from '@/components/inboxPage/chat/messages/responser/ScheduleMessage';
  import GeneratePromptInbox from '@/components/base/GeneratePrompt/GeneratePromptInbox.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton';
  import SenderInfo from '@/components/inboxPage/chat/SenderInfo.vue';
  import EventTypes from '@/components/inboxPage/chat/events/EventTypes.vue';
  import { Picker, EmojiIndex } from 'emoji-mart-vue-fast';
  import data from 'emoji-mart-vue-fast/data/all.json';
  import 'emoji-mart-vue-fast/css/emoji-mart.css';
  import { sendMessage } from '@/api/profilesMethods';
  import DefaultContenteditable from '@/components/base/uiKit/DefaultConteneditable.vue';
  import { generateEmojiPickerLocale } from '@/utils/translation/utils';
  import { useContenteditable } from '@/use/useContenteditable';
  import { ref, computed, onMounted, watch } from 'vue';
  import { useStore } from '@/store';
  import { useI18n } from '@/i18n';
  import { useSnack } from '@/lib/useSnack';
  import { useClosable, ClosableData } from '@/use/useClosable';
  import { DraftMessage } from '@/components/inboxPage/chat/utils/draftMessage';
  import { notifyError } from '@/use/notifyError';
  import { MessageTypes } from '@/types/messages';

  const props = defineProps({
    messageToEdit: {
      type: String,
      default: '',
    },
    profile: {
      type: Object,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isEditMode: {
      type: Boolean,
      default: true,
    },
    showSender: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['update-message', 'exit-edit-mode', 'send']);

  const { t } = useI18n();
  const { $snack } = useSnack();
  const { saveRange } = useContenteditable();

  const store = useStore();
  const selectedCredential = computed(() => store.getters['credentials/selectedCredential']);

  const messageAreaRef = ref(null);
  const buttonsResponserRef = ref(null);

  const active = ref(false);

  const showInputFeature = ref(false);

  // #region Closable
  onMounted(() => {
    useClosable(new ClosableData(
      scheduleRef,
      scheduleTogglerRef,
      isScheduleActive
    ));

    useClosable(new ClosableData(
      savedResponsesRef,
      ref(buttonsResponserRef.value.responsesToggler),
      isResponsesActive
    ));

    useClosable(new ClosableData(
      emojiesPickerRef,
      ref(buttonsResponserRef.value.emojiesPickerToggler),
      isEmojiesActive
    ));

    useClosable(new ClosableData(
      eventTypesRef,
      ref(buttonsResponserRef.value?.eventsToggler || null),
      isEventsActive
    ));
  });
  // #endregion

  // #region Draft messages
  const draftMessages = computed(() => ['profile/draftMessages']);

  onMounted(() => {
    const draft = draftMessages.value[props.profile._id];
    if (draft?.linkedIn) {
      message.value = draft.linkedIn.draft;
    } else {
      message.value = '';
    }
  });

  watch(() => message?.value, () => {
    const draftMessage = new DraftMessage(this.profile._id, this.message, MessageTypes.LINKED_IN);
    store.commit('profile/UPDATE_DRAFT_MESSAGE', draftMessage);
  });
  // #endregion

  // #region Message
  const message = ref('');

  const textareaPlaceholder = computed(() => {
    return props.isDisabled
      ? props.profile.inmail_status === 'pending'
        ? 'This user has not accepted your inMail yet'
        : 'This user has declined your inMail'
      : t('placeholders.message');
  });

  watch(() => props.profile._id, () => {
    message.value = '';
  });

  watch(() => props.messageToEdit, () => {
    if (newMessageToEdit) {
      message.value = newMessageToEdit;
      messageAreaRef.value.focus();
    } else {
      message.value = newMessageToEdit;
      messageAreaRef.value.blur();
    }
  });

  function handleInput () {
    saveRange();
  }

  const sendMessageHandler = notifyError(async (date = null) => {
    if (props.isEditMode) {
      emit('update-message', message.value);
      return;
    }

    if (date) {
      date = transformDate(date);
    }

    try {
      const newMessage = await sendMessage(
        props.profile._id,
        selectedCredential.value._id,
        message.value,
        date
      );
      emit('send', newMessage);
      message.value = '';
    } catch (e) {
      $snack.error(e.message);
    }
  });
  // #endregion

  // #region Schedule
  const scheduleRef = ref(null);
  const isScheduleActive = ref(false);
  const scheduleTogglerRef = ref(null);
  // #endregion

  // #region Responses
  const savedResponsesRef = ref(null);
  const isResponsesActive = ref(false);

  function insertResponse (response) {
    message.value = response;
    isResponsesActive.value = false;
  }
  // #endregion

  // #region Emojies
  const emojiesData = new EmojiIndex(data);

  const emojiesPickerRef = ref(null);
  const isEmojiesActive = ref(false);

  const emojiPickerLocales = computed(() => generateEmojiPickerLocale());

  function insertEmoji (emoji) {
    message.value += emoji.native;
  }
  // #endregion

  // #region Events
  const eventTypesRef = ref(null);
  const isEventsActive = ref(false);

  const eventTypesLinkParams = { utm_content: String(props.profile._id) };

  function insertEventLink (link) {
    message.value += link;
  }
  // #endregion

  // #region Helpers
  function transformDate (date = new Date()) {
    if (!selectedCredential.value.ui_pc_timezone) {
      return new Date(date - new Date().getTimezoneOffset() * 60 * 1000);
    }
    return date.toISOString().match(/[^T]+T[^:]+[^.]+/g)[0] + '.000Z';
  }
  // #endregion
</script>

<style lang="scss">
.responser {
  position: relative;
  z-index: 1;
  display: grid;
  padding: 16px;
  border-radius: 8px;
  background: var(--background-color);
  border: var(--border-default-50);

  &__content {
    @include flex-row;
    word-break: break-word;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
  }

  &__control {
    display: flex;
    gap: 4px;
    justify-content: space-between;
  }

  &__textarea {
    flex-grow: 1;
    @include text_s;
    border: 0;
    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 200px;
    &::-webkit-scrollbar {
      display: none;
    }
  };

  &__saved-responses,
  &__emojies,
  &__schedule,
  &__events {
    position: absolute;
    bottom: calc(100% + 16px);

  }

  &__saved-responses {
    left: 0;
  }

  &__emojies,
  &__schedule {
    right: 0;
  }

  &__events {
    left: 0px;
    right: 0px;
    box-shadow: var(--shadow-s);
  }
}

.emoji-mart {
  background-color: var(--message-test);
}

.emoji-mart-search input {
  background-color: var(--secondary-btn-bg);
  border: 1px solid var(--border-cl);
  color: var(--text-color);
}

.emoji-mart-category-label span {
  background-color: var(--message-test);
  color: var(--text-color);
}
</style>
