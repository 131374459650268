<template>
  <div class="inbox-task-card__description">
    <div
      v-if="description || isEditMode"
      class="flex-row flex-justify-content-between mb-05"
    >
      <p class="text_xs color_tertiary">
        {{ label }}
      </p>

      <default-button
        v-show="isExpanded && isEditable"
        :title="isEditMode ? $t('actions.save') : $t('actions.edit')"
        size="xs"
        form="rectangle"
        :icon-name="isEditMode ? 'bx-check' : 'bx-edit'"
        title-color="color_primary"
        @click.native.stop
        @action="isEditMode ? saveEditedDescription() : enterDescriptionEditMode()"
      />
    </div>

    <template v-if="isEditMode && isEditable">
      <rich-editor
        v-if="isHtml"
        key="dynamicEditor"
        class="tw-rounded-xl"
        editor-class="text_s px-15 py-1 background_neutral_50"
        :content="updatedDescription"
        :with-signature="false"
        :editable="isEditMode"
        :autofocus-on-edit="true"
        placeholder="Type your message..."
        @update:content="updatedDescription = $event"
      />

      <textarea
        v-else
        :id="label"
        ref="descriptionTextareaRef"
        v-model="updatedDescription"
        :name="label"
        rows="1"
        class="default-textarea text_s no-resize"
        @input="resizeTextarea"
      />
    </template>

    <rich-editor
      v-else-if="isHtml"
      key="staticEditor"
      class="text_s"
      :class="{ 'tw-rounded-xl': isExpanded}"
      :editor-class="'tw-transition-[padding] ' + (isExpanded ? 'background_neutral_50 px-15 py-1' : 'rich-editor_preview background_none')"
      :content="description"
      :with-signature="false"
      :editable="false"
      :has-toolbar="false"
      placeholder="Type your message..."
    />

    <p
      v-else-if="description"
      ref="descriptionPreviewRef"
      class="inbox-task-card__description-text text_s"
      :class="{
        'background_neutral_50 inbox-task-card__description-text_expanded': isExpanded,
      }"
    >
      {{ description }}
    </p>

    <div
      v-if="signature && isExpanded"
      class="tw-mt-1 tw-w-full *:!tw-text-s"
      v-html="signature.message"
    />
  </div>
</template>

<script setup>
  import { ref, watch } from 'vue';
  import RichEditor from '@/components/richEditor/RichEditor.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { useTextareaResize } from '@/use/useTextareaResize';

  const props = defineProps({
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    signature: {
      type: Object,
      default: () => null,
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
    isHtml: {
      type: Boolean,
      default: false,
    },
  });

  const emit = defineEmits(['update:description', 'update:is-edit-mode']);

  const descriptionTextareaRef = ref();
  const { resizeTextarea } = useTextareaResize(descriptionTextareaRef);

  watch(() => descriptionTextareaRef.value, (newVal) => {
    if (newVal) {
      descriptionTextareaRef.value.focus();
      resizeTextarea();
    }
  });

  const updatedDescription = ref(props.description);

  const enterDescriptionEditMode = () => {
    updatedDescription.value = props.description;

    emit('update:is-edit-mode', true);
  };

  const saveEditedDescription = () => {
    emit('update:description', updatedDescription.value);
  };

  const descriptionPreviewRef = ref(null);

  watch(() => props.isExpanded, (newVal) => {
    if (!newVal && descriptionPreviewRef.value) {
      descriptionPreviewRef.value.scrollTo({ top: 0 });
    }
  });
</script>

<style lang="scss">
.rich-editor_preview {
  .rich-editor__input {
    * {
      font-size: inherit;
      line-height: inherit;
    }
  }
}

.inbox-task-card__description {
  &-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-line;
    word-break: break-word;
    padding: 0px;
    border-radius: 8px;
    border: 1px solid transparent;
    transition: background-color .2s ease-out, padding .2s ease-out, max-height 2s ease-out;

    &_expanded {
      display: block;
      padding: 8px 12px;
      max-height: 256px;
      overflow: auto;
    }
  }
}
</style>
