const AuthPage = () => import('@/views/auth/AuthPage');
const SignUpPage = () => import('@/views/auth/SignUpPage');
const ForgotPasswordPage = () => import('@/views/auth/ForgotPasswordPage');
const RestorePasswordPage = () => import('@/views/auth/RestorePasswordPage');
const GoHighLevelPage = () => import('@/views/auth/GoHighLevelPage');

const auth = [
  {
    path: '/auth',
    name: 'auth',
    redirect: '/auth/sign-in',
  },
  {
    path: '/auth/sign-in',
    name: 'sign-in',
    component: AuthPage,
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/auth/sign-up',
    name: 'sign-up',
    component: SignUpPage,
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/auth/integration/third-party-login',
    name: 'third-party-login',
    component: GoHighLevelPage,
    meta: {
      layout: 'EmptyLayout',
    },
  },
  {
    path: '/auth/forgot-password',
    name: 'forgot-password',
    component: ForgotPasswordPage,
    meta: {
      layout: 'AuthLayout',
    },
  },
  {
    path: '/auth/restore-password',
    name: 'restore-password',
    component: RestorePasswordPage,
    meta: {
      layout: 'AuthLayout',
    },
  },
];

export default auth;
