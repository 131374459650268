/*
  © Copyright, Dexima Inc.
  2023 — All rights reserved.
*/
import { ref } from 'vue';
import { defineStore } from 'pinia';
import { getCredentialIntegrations } from '@/api/credentialsMethods';
export const useIntegrationsStore = defineStore('integrations', () => {
    const integrationsList = ref([]);
    function setIntegrationsList(list) {
        integrationsList.value = list;
    }
    async function setCredentialIntegrationsList(credId) {
        integrationsList.value = await getCredentialIntegrations(credId);
    }
    return {
        integrationsList,
        setIntegrationsList,
        setCredentialIntegrationsList,
    };
});
