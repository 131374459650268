<template>
  <div class="parent-size flex-column">
    <ul
      v-if="emails.length"
      class="flex-column flex-gap-16"
    >
      <card-emails
        v-for="email in emails"
        :key="email._id"
        :email="email"
        :profile="profile"
        @reply="reply"
      />
    </ul>
    <ul
      v-else-if="loading"
      class="flex-column flex-gap-16"
    />
    <div class="flex flex-align-items-center mt-auto mb-auto margin-bottom flex-column-centered pb-15 pt-15">
      <p
        v-if="!emails.length && showSendEmailBtn"
        class="text_s list-emails__title"
      >
        {{ $t("inboxPage.chatSection.notifications.emailsAppear") }}
      </p>
      <default-button
        v-if="showSendEmailBtn"
        :title="$t('inboxPage.chatSection.actions.sendEmail')"
        color="primary"
        form="rectangle"
        size="m"
        @action="reply"
      />
    </div>
  </div>
</template>
<script>
  import CardEmails from '@/components/inboxPage/chat/emails/CardEmails.vue';
  import DefaultButton from '@/components/base/uiKit/DefaultButton.vue';
  import { useUpgradeModal } from '@/use/useUpgradeModal';

  export default {
    name: 'ListEmails',
    components: {
      CardEmails,
      DefaultButton,
    },
    props: {
      emails: {
        type: Array,
        required: true,
      },
      profile: {
        type: Object,
        required: true,
      },
      showSendEmailBtn: {
        type: Boolean,
        default: true,
      },
      loading: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      reply (email = null) {
        useUpgradeModal(() => {
          this.$emit('reply', email);
        }, 'email_automation');
      },
    },
  };
</script>
<style lang="scss">
.list-emails {
  &__title {
    margin-bottom: 16px;
    color: var(--icon-subdued);
    font-weight: var(--font-weight-400);
  }
}
</style>
